import React from 'react';

function App() {
  return (
    <div className='grid place-items-center h-screen'>
      <div className='text-center'>
        <h1 className='font-martel font-black text-3xl border-b pb-2 mb-2'>
          <span className='text-gray-600'>Daryl</span>
          &nbsp;<span className='text-gray-400'>&</span>&nbsp;
          <span className='text-brand-500'>James</span>
        </h1>
        <h2 className='text-gray-700'>Technology for Crafters</h2>
      </div>
    </div>
  );
}

export default App;
