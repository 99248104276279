import React from 'react';
import { hydrate, render } from "react-dom";
import App from './App';
import './index.css';

function AppContainer() {
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<AppContainer />, rootElement);
} else {
  render(<AppContainer />, rootElement);
}
